<template>
  <div class="list-stocks">
    <KTCodePreview v-bind:title="'Danh sách phiếu kiểm tra tồn kho'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="showCreateModal"
              class="ml-4"
              v-if="checkPermissions(['STOCK_CHECK_INSERT'])"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span>
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo
                    mới</span
                  >
                </div>
              </template>
              <b-dropdown-item
                v-if="checkPermissions(['STOCK_CHECK_INSERT'])"
                @click="routeProduct('import-inventory-check')"
              >
                <i style="font-size: 1rem" class="far fa-file-excel mr-2"></i>
                <span>Nhập từ file excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="params.searchFromDay"
                ></date-picker>
              </b-col>
              <b-col>
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="params.searchToDay"
                ></date-picker>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-form-input
              v-model.trim="params.code"
              type="text"
              placeholder="Mã phiếu kiểm tồn"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-select
              v-if="checkPermissions(['STOCK_CHECK_VIEW_DETAIL'])"
              v-model="params.status"
              :options="statusOptions"
              size="sm"
            ></b-form-select>
            <b-form-select
              v-else
              v-model="params.status"
              :options="statusOptionUser"
              size="sm"
            ></b-form-select>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filteredOptionsStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name', 'shortName']"
              :searchable="true"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5 mt-5">
          <b-col md="1">
            <b-button
              style="font-weight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <b-table
          :table-class="'table-centered'"
          :items="items"
          :fields="fields"
          head-row-variant="secondary"
          outlined
        >
          <template v-slot:cell(code)="data">
            <b-link
              id="inventory-check-modal"
              @click="editInventoryCheckItem(data.item)"
            >
              {{ data.item.code }}
            </b-link>
          </template>
          <template v-slot:cell(status)="data">
            <StatusLabel
              :status="data.item.status"
              :statusName="data.item.statusName"
            />
          </template>
          <template v-slot:cell(note)="data">
            <p v-if="data.item.note" :title="data.item.note" v-b-tooltip.hover>
              {{ truncateText(data.item.note) }}
            </p>
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editInventoryCheckItem(data.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Xem chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermissions(['STOCK_CHECK_DELETE'])"
                  @click="
                    showDeleteAlert(
                      'Xoá phiếu kiểm kê',
                      'Bạn có chắc muốn xoá phiếu kiểm kê này!',
                      data.item.id,
                      deleteStockCheckItem,
                    )
                  "
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template v-slot:cell(productType)="data">
            {{ productTypeName[data.item.productType] || 'Tất cả' }}
          </template>
          <template v-slot:cell(createdAt)="data">
            {{ data.item.createdAt }}
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-col>
              <BPaginationNavCustom
                :number-of-pages="numberOfPage || 1"
                :current-page="page"
                :total-page="numberOfPage"
                :linkParams="{}"
                @page-change="fetchDataCheckSheets()"
              >
              </BPaginationNavCustom>
            </b-col>
          </b-col>
        </b-row>
        <InventoryCheckAddModal
          v-on:fetchInventoryChecks="fetchDataCheckSheets()"
          ref="add-inventory-check-modal"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import InventoryCheckAddModal from '@/view/components/inventoryCheck/InventoryCheckAddModal.vue';
import StatusLabel from '@/view/components/inventoryCheck/StatusLabel.vue';
import {
  makeToastFaile,
  removeAccents,
  makeToastSuccess,
} from '@/utils/common';
import { STOCK_CHECK_SHEET_STATUS } from '@/utils/enum';
import { cloneDeep, isNil, omitBy, omit } from 'lodash';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import { checkPermissions } from '@/utils/saveDataToLocal';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { formatDBDate, DP_CONFIG } from '@/utils/date';

export default {
  data() {
    return {
      page: 1,
      productTypeName: {
        1: 'Sản phẩm',
        2: 'Sản phẩm theo IMEI',
      },
      selectedCompany: null,
      checkSheets: [],
      valueStore: [],
      filteredOptionsStore: [],
      optionsStore: [],
      totalItem: 0,
      numberOfPage: 0,
      selectedCheckSheetId: null,
      dpConfigs: DP_CONFIG,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          thStyle: { width: '15%', fontSize: '1.2rem' },
          tdClass: 'font-weight-bold text-primary text-left  align-middle',
          thClass: 'text-center text-dark',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-left  align-middle',
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-left  align-middle',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-right  align-middle ',
        },
        {
          key: 'productType',
          label: 'Loại sản phẩm',
          thStyle: { width: '15%', fontSize: '1.2rem' },
          thClass: 'text-center  text-dark',
          tdClass: 'text-center  align-middle ',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { width: '10%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
          tdClass: 'text-center align-middle',
        },
        {
          key: 'note',
          label: 'Ghi chú',
          thClass: 'text-center text-dark',
          tdClass: 'text-left align-middle',
          thStyle: { width: '20%', fontSize: '1.2rem' },
        },
        {
          key: 'action',
          label: '',
          tdClass: 'text-right align-middle',
          thStyle: { width: '3%', fontSize: '1.2rem' },
          thClass: 'text-center text-dark',
        },
      ],
      items: [],
      onLoading: false,
      statusOptions: [
        { value: null, text: 'Chọn trạng thái' },
        { value: STOCK_CHECK_SHEET_STATUS.NEW, text: 'Mới' },
        { value: STOCK_CHECK_SHEET_STATUS.DIFFERENCE, text: 'Tồn lệch' },
        { value: STOCK_CHECK_SHEET_STATUS.STOCK_DEBT, text: 'Truy thu' },
        { value: STOCK_CHECK_SHEET_STATUS.DONE, text: 'Hoàn thành' },
      ],
      statusOptionUser: [
        { value: null, text: 'Chọn trạng thái' },
        { value: STOCK_CHECK_SHEET_STATUS.NEW, text: 'Mới' },
        { value: STOCK_CHECK_SHEET_STATUS.DONE, text: 'Hoàn thành' },
      ],
      params: {
        page: 1,
        pageSize: 10,
        searchFromDay: '',
        searchToDay: '',
        status: null,
        storeIds: [],
        code: null,
      },
    };
  },
  methods: {
    checkPermissions,
    showDeleteAlert,
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          this.optionsStore = stores.map((element) => {
            return {
              id: element.id,
              name: element.shortName,
              checked: false,
            };
          });
          this.filteredOptionsStore = this.optionsStore;
          if (stores.length === 1) {
            this.optionsStore[0].checked = true;
          }
        }
      });
    },
    onFilter() {
      this.setParamsFilter();
      this.fetchDataCheckSheets();
    },

    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },

    editInventoryCheckItem(checkSheet) {
      this.$router.push({
        name: 'upsert-inventory-check',
        query: { id: checkSheet.id },
      });
    },
    showCreateModal() {
      this.$refs['add-inventory-check-modal'].showModal();
    },
    fetchDataCheckSheets: function () {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;
      const params = this.getParamsFilter();
      ApiService.query('v2/stock-check-sheet', {
        params,
      }).then(({ data }) => {
        this.totalItem = data.data.totalRow;
        this.numberOfPage = data.data.totalPage;
        this.items = data.data.dataset;
      });
    },
    setParamsFilter() {
      const params = this.getParamsFilter();
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-inventory-check',
        query: omitBy(omit(params, ['page', 'pageSize']), isNil),
      });
      return params;
    },
    getParamsFilter() {
      const storeIds = this.valueStore.map((element) => element.id);
      return {
        page: this.page,
        pageSize: 10,
        searchFromDay: formatDBDate(this.params.searchFromDay),
        searchToDay: formatDBDate(this.params.searchToDay),
        status: this.params.status,
        code: this.params.code,
        storeIds,
      };
    },
    async deleteStockCheckItem(id) {
      try {
        const response = await ApiService.delete(`v2/stock-check-sheet/${id}`);
        const { message } = response.data;
        this.fetchDataCheckSheets();
        makeToastSuccess(message);
      } catch (err) {
        console.log(err);
        const { message } = err.response.data;
        makeToastFaile(message);
      }
    },
    routeProduct(namePath) {
      this.$router.push({
        name: `${namePath}`,
      });
    },
    truncateText(text) {
      const MAX_STRING = 100;
      if (!text || !text.length) {
        return '';
      }
      return text.length < MAX_STRING
        ? text
        : text.substring(0, MAX_STRING) + '...';
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kiểm tra tồn kho' },
      { title: 'Danh sách phiếu kiểm tra tồn kho' },
    ]);
    this.onFilter();
  },
  created() {
    this.fetchStoreByUser();
  },
  components: {
    KTCodePreview,
    InventoryCheckAddModal,
    StatusLabel,
    BDropdownCustom,
    BPaginationNavCustom,
  },
};
</script>

<style scoped>
.check-sheet-list {
  max-height: 600px !important;
  overflow-y: auto !important;
}

.table-centered td {
  vertical-align: middle;
}
</style>
